import { PlateEditor, Value } from "@udecode/plate-common";
import React, { useContext } from "react";

import { RichTextMentionData } from "./RichTextMentionPlugin";

export interface RichTextMentionContext {
  onMentionSearch: (searchString: string) => Promise<RichTextMentionData[]>;
  onSelectSideEffect?: (
    editor: PlateEditor<Value>,
    item: RichTextMentionData,
  ) => void;
}

export const RichTextMentionContext =
  React.createContext<RichTextMentionContext>({
    onMentionSearch: () => Promise.resolve([]),
  });

export function useRichTextMentionContext(): RichTextMentionContext {
  return useContext(RichTextMentionContext);
}
