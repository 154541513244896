import { gql, useApolloClient } from "@apollo/client";
import {
  CellId,
  DataConnectionId,
  GroupId,
  MagicUsageStatus,
  MentionedDataframeName,
  RichTextDocument,
  ScopeItemType,
  UserId,
  assertNever,
  getMentionElements,
  notEmpty,
} from "@hex/common";
import { useCallback } from "react";

import { useScopeGetter } from "../../../appsession-multiplayer/state-hooks/scopeStateHooks.js";
import { useCellsContentsGetter } from "../../../hex-version-multiplayer/state-hooks/cellsContentsStateHooks.js";
import { useCurrentUser } from "../../../hooks/me/useCurrentUser.js";
import { ORG_ID } from "../../../orgs.js";
import { useProjectContext } from "../../../util/projectContext.js";
import { useHexFlag } from "../../../util/useHexFlags.js";

import { RichTextMentionData } from "./RichTextMentionPlugin.js";
import {
  DataSourceMentionTableResultFragment,
  MentionSearchDocument,
  MentionSearchQuery,
  MentionSearchQueryVariables,
} from "./useMentionSearch.generated.js";

gql`
  fragment DataSourceMentionTableResultFragment on DataSourceTable {
    name
    id
    pinned
    dbtDescription
    comment
    metadata {
      id
      magicDescription
      magicUsageStatus
      status {
        ...StatusFragment
      }
    }
    dataSourceSchema {
      name
      id
      metadata {
        id
        magicUsageStatus
        status {
          ...StatusFragment
        }
      }
      dataSourceDatabase {
        name
        id
        dataConnectionId
        metadata {
          id
          magicUsageStatus
          status {
            ...StatusFragment
          }
        }
      }
    }
  }
`;

gql`
  fragment UserMentionSearchResult on User {
    id
    name
    email
    imageUrl
  }

  query MentionSearch(
    $searchString: String!
    $orgId: OrgId!
    $hexId: HexId!
    $dataConnectionId: [DataConnectionId!]!
    $searchUsersAndGroups: Boolean!
    $searchTables: Boolean!
    $searchHexes: Boolean!
    $recentTables: Boolean!
    $userId: UserId!
  ) {
    searchOrgUsers(orgId: $orgId, searchString: $searchString, pageSize: 4)
      @include(if: $searchUsersAndGroups) {
      ...UserMentionSearchResult
    }
    searchHexUsers(hexId: $hexId, searchString: $searchString, pageSize: 3)
      @include(if: $searchUsersAndGroups) {
      ...UserMentionSearchResult
    }
    searchOrgGroups(orgId: $orgId, searchString: $searchString, pageSize: 3)
      @include(if: $searchUsersAndGroups) {
      id
      ...GroupPermissionFragment
    }
    quickSearchHexes(searchQuery: $searchString, fields: [TITLE], first: 3)
      @include(if: $searchHexes) {
      edges {
        node {
          id
          hexVersion {
            id
            title
            hex {
              id
              hexType
            }
          }
        }
      }
    }
    searchSchemaElements(
      dataConnectionId: $dataConnectionId
      searchQuery: $searchString
      schemaFilters: null
      tableFilters: null
      columnFilters: null
      resultTypes: [TABLES]
      tiebreakResults: true
    ) @include(if: $searchTables) {
      tables {
        tableSearchResults {
          ...DataSourceMentionTableResultFragment
        }
      }
    }
    recentlyUsedTables(userId: $userId, dataConnectionId: $dataConnectionId)
      @include(if: $recentTables) {
      id
      ...DataSourceMentionTableResultFragment
    }
  }
`;

type UseMentionSearchArgs = {
  searchUsersAndGroups: boolean;
  searchDataframes: boolean;
  searchHexes: boolean;
  cellId?: CellId;
} & (
  | {
      disallowDuplicateMentions: true;
      getRichText: () => RichTextDocument;
    }
  | {
      disallowDuplicateMentions: false;
      getRichText?: () => RichTextDocument;
    }
) &
  (
    | {
        searchTables: true;
        dataConnectionId: DataConnectionId;
        restrictToConnection: boolean;
      }
    | {
        searchTables: false;
      }
  );

/**
 * Handles search for for mentionable elements in the rich text editor.
 * Currently supports users/groups, tables, and dataframes. No clever ordering is
 * implemented yet, results are just concatenated.
 */
export function useMentionSearch(
  args: UseMentionSearchArgs,
): (searchString: string) => Promise<RichTextMentionData[]> {
  const client = useApolloClient();
  const { hexId } = useProjectContext();
  const currentUser = useCurrentUser();
  const unidfQueryMode = useHexFlag("unidf-query-mode");

  const getDfs = useScopeGetter();
  const getCellContents = useCellsContentsGetter();
  return useCallback(
    async (searchString: string) => {
      const existingMentions = args.getRichText
        ? getMentionElements(args.getRichText())
        : undefined;
      let combinedResults: RichTextMentionData[] = [];
      let recentTables: RichTextMentionData[] = [];

      if (currentUser == null) {
        return [];
      }

      if (args.searchDataframes) {
        const allDfs = unidfQueryMode
          ? Object.values(getDfs())
              .filter(
                (obj) =>
                  (obj?.type === ScopeItemType.DATAFRAME ||
                    obj?.type === ScopeItemType.REMOTE_DATAFRAME) &&
                  !obj.isHidden,
              )
              .filter(notEmpty)
          : Object.values(getDfs())
              .filter(
                (obj) => obj?.type === ScopeItemType.DATAFRAME && !obj.isHidden,
              )
              .filter(notEmpty);
        const dfNames = allDfs.map((scopeItem) => scopeItem.name);
        const sqlCellData = Object.values(getCellContents())
          .filter(
            (cell) =>
              cell?.__typename === "SqlCell" &&
              cell.cellId !== args.cellId &&
              cell.deletedDate == null &&
              dfNames.includes(cell.resultVariable),
          )
          .flatMap((cell) =>
            cell?.__typename === "SqlCell"
              ? [
                  {
                    resultVariable: cell.resultVariable,
                    connectionId: cell.connectionId,
                  },
                ]
              : [],
          );
        // We want to search across all in-scope dataframes, unless:
        // - `searchTables` is true,
        // - the dataConnectionId is non-null,
        // - and either:
        //     - we're explicitly restricting search to a particular connection, or
        //     - the user has already mentioned a table
        let dfsToSearch = dfNames;
        if (
          args.searchTables &&
          args.dataConnectionId != null &&
          (args.restrictToConnection ||
            (existingMentions ?? []).some(
              (mention) => mention.mentionType === "table",
            ))
        ) {
          dfsToSearch = sqlCellData
            .filter((cell) => cell.connectionId === args.dataConnectionId)
            .map((cell) => cell.resultVariable);
        }
        combinedResults = [
          ...combinedResults,
          ...dfsToSearch
            .filter((df) =>
              df.toLowerCase().includes(searchString.toLowerCase()),
            )
            // Sort by length, so results with higher proportional overlap come first
            .sort((df) => df.length)
            .map((dfName) => {
              const correspondingCellData = sqlCellData.find(
                (cell) => cell.resultVariable === dfName,
              );
              const df = allDfs.find((d) => d.name === dfName);
              return {
                mentionType: "dataframe" as const,
                id: dfName,
                name: dfName as MentionedDataframeName,
                columns: Object.keys(df?.dataFrameSchema?.columns ?? {}),
                connectionId:
                  args.searchTables && correspondingCellData != null
                    ? correspondingCellData.connectionId ?? null
                    : null,
              };
            }),
        ];
      }
      if (args.searchUsersAndGroups || args.searchTables) {
        const result = await client.query<
          MentionSearchQuery,
          MentionSearchQueryVariables
        >({
          query: MentionSearchDocument,
          variables: {
            hexId,
            orgId: ORG_ID,
            searchString,
            searchHexes: args.searchHexes,
            searchUsersAndGroups: args.searchUsersAndGroups,
            searchTables: args.searchTables && args.dataConnectionId != null,
            // Unfortunately, we have to pass a "real" dataConnectionId to GQL here, even if we're not
            // searching tables, so we just cast an empty string iff we're not searching for tables.
            dataConnectionId:
              args.searchTables && args.dataConnectionId != null
                ? args.dataConnectionId
                : ("" as DataConnectionId),

            recentTables: args.searchTables && searchString.length === 0,
            userId: currentUser.id,
          },
        });
        if (result.data == null) {
          return combinedResults;
        }
        if (args.searchUsersAndGroups) {
          const addedUsersAndGroups = new Set<UserId | GroupId>();
          for (const user of (result.data.searchHexUsers ?? []).concat(
            result.data.searchOrgUsers ?? [],
          )) {
            if (!addedUsersAndGroups.has(user.id)) {
              combinedResults.push({ ...user, mentionType: "user" });
              addedUsersAndGroups.add(user.id);
            }
          }

          for (const group of result.data.searchOrgGroups ?? []) {
            if (!addedUsersAndGroups.has(group.id)) {
              combinedResults.push({
                mentionType: "group",
                id: group.id,
                name: group.groupName,
              });
              addedUsersAndGroups.add(group.id);
            }
          }
        }
        if (args.searchHexes) {
          for (const {
            node: { hexVersion },
          } of result.data.quickSearchHexes?.edges ?? [])
            combinedResults.push({
              mentionType: "hex",
              id: hexVersion.hex.id,
              title: hexVersion.title,
              hexType: hexVersion.hex.hexType,
            });
        }
        if (args.searchTables) {
          const transformTable = (
            table: DataSourceMentionTableResultFragment,
          ): RichTextMentionData => ({
            mentionType: "table" as const,
            id: table.id,
            databaseName: table.dataSourceSchema.dataSourceDatabase.name,
            schemaName: table.dataSourceSchema.name,
            description:
              table.metadata?.magicDescription ||
              table.dbtDescription ||
              table.comment,
            pinned: table.pinned,
            status:
              table.metadata?.status ??
              table.dataSourceSchema.metadata?.status ??
              table.dataSourceSchema.dataSourceDatabase.metadata?.status ??
              null,
            tableName: table.name,
            connectionId: args.dataConnectionId,
          });

          const tables = (
            result.data.searchSchemaElements?.tables.tableSearchResults ?? []
          )
            .filter(
              (table) =>
                table.metadata?.magicUsageStatus !==
                  MagicUsageStatus.EXCLUDED &&
                table.dataSourceSchema.metadata?.magicUsageStatus !==
                  MagicUsageStatus.EXCLUDED &&
                table.dataSourceSchema.dataSourceDatabase.metadata
                  ?.magicUsageStatus !== MagicUsageStatus.EXCLUDED,
            )
            .map((table) => transformTable(table));

          combinedResults = [...combinedResults, ...tables];
          recentTables = (result.data.recentlyUsedTables ?? []).map(
            transformTable,
          );
        }
      }

      // If we're searching schemas and there's no search term yet, we'll return a few dataframes and the most recently used tables
      if (
        args.searchTables &&
        args.searchDataframes &&
        searchString.length === 0
      ) {
        const dfs = combinedResults.filter(
          (r) => r.mentionType === "dataframe",
        );
        combinedResults = [...dfs.slice(0, 3), ...recentTables];
      }

      if (args.disallowDuplicateMentions && existingMentions != null) {
        combinedResults = combinedResults.filter((result) => {
          const { mentionType } = result;
          switch (mentionType) {
            case "dataframe":
              return !existingMentions.some(
                (mention) =>
                  mention.mentionType === "dataframe" &&
                  mention.name === result.id,
              );
            case "table":
              return !existingMentions.some(
                (mention) =>
                  mention.mentionType === "table" &&
                  mention.tableId === result.id,
              );
            case "user":
              return !existingMentions.some(
                (mention) =>
                  mention.mentionType === "user" &&
                  mention.userId === result.id,
              );
            case "group":
              return !existingMentions.some(
                (mention) =>
                  mention.mentionType === "group" &&
                  mention.groupId === result.id,
              );
            case "hex":
              return !existingMentions.some(
                (mention) =>
                  mention.mentionType === "hex" && mention.hexId === result.id,
              );
            default:
              assertNever(mentionType, mentionType);
          }
        });
      }
      return combinedResults;
    },
    [args, currentUser, unidfQueryMode, getDfs, getCellContents, client, hexId],
  );
}
