import {
  DataConnectionId,
  DataSourceTableId,
  DataframeMention,
  GroupId,
  GroupMention,
  HexId,
  HexMention,
  HexType,
  MentionElement as MentionElementType,
  MentionedDataframeName,
  TableMention,
  UserId,
  UserMention,
  assertNever,
  guardNever,
  uuid,
} from "@hex/common";
import {
  PlateEditor,
  PlateElement,
  PlateElementProps,
  PlatePlugin,
  PlatePluginComponent,
  Value,
  insertNodes,
} from "@udecode/plate-common";
import {
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  type TMentionItemBase,
  createMentionPlugin as createMentionPlatePlugin,
  getMentionOnSelectItem,
} from "@udecode/plate-mention";
import { last } from "lodash";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useHexFlag } from "../../../util/useHexFlags";
import { StatusFragment } from "../../app/ProjectLabels.generated";
import { StatusLabel } from "../../common/labels/StatusLabel";
import {
  ComponentIconV2,
  DataframeIcon,
  ProjectIcon,
  TableCellIcon,
} from "../../icons/CustomIcons.js";
import { GroupAvatar } from "../../user/GroupAvatar.js";
import { UserAvatar } from "../../user/UserAvatar";
import { MentionElementRenderer } from "../elementRenderers";

import {
  DataframeMentionDetails,
  PinnedTableIcon,
  TableMentionDetails,
} from "./MentionDetails";
import { RichTextCombobox, RichTextComboboxItem } from "./RichTextCombobox";
import { useRichTextMentionContext } from "./RichTextMentionContext";

// ----------------------------------------------------------------------------
// Types
// ----------------------------------------------------------------------------

export type RichTextMentionData =
  | RichTextUserMentionData
  | RichTextGroupMentionData
  | RichTextTableMentionData
  | RichTextDataframeMentionData
  | RichTextHexMentionData;

export interface RichTextUserMentionData {
  readonly id: UserId;
  readonly name: string | null;
  readonly email: string;
  readonly imageUrl: string | null;
  readonly mentionType: "user";
}

export interface RichTextGroupMentionData {
  readonly id: GroupId;
  readonly name: string;
  readonly mentionType: "group";
}

export interface RichTextTableMentionData {
  readonly tableName: string;
  readonly schemaName: string;
  readonly databaseName: string | null;
  readonly mentionType: "table";
  readonly id: DataSourceTableId;
  readonly connectionId: DataConnectionId;
  readonly description: string | null;
  readonly pinned: boolean;
  readonly status: StatusFragment | null;
}

export interface RichTextDataframeMentionData {
  readonly mentionType: "dataframe";
  readonly id: string;
  readonly name: MentionedDataframeName;
  readonly connectionId: DataConnectionId | null;
  readonly columns: string[];
}

export interface RichTextHexMentionData {
  readonly mentionType: "hex";
  readonly id: HexId;
  readonly hexType: HexType;
  readonly title: string;
}

// ----------------------------------------------------------------------------
// Plugin
// ----------------------------------------------------------------------------

type RichTextMentionItem = RichTextMentionData & TMentionItemBase;

type CreateMentionNode<T extends MentionElementType> = Omit<T, "children"> & {
  value: string;
};

export function createMentionPlugin(): PlatePlugin {
  return createMentionPlatePlugin<RichTextMentionItem>({
    options: {
      createMentionNode: (item: RichTextMentionItem) => {
        let mentionElement:
          | CreateMentionNode<GroupMention>
          | CreateMentionNode<UserMention>
          | CreateMentionNode<TableMention>
          | CreateMentionNode<DataframeMention>
          | CreateMentionNode<HexMention>;
        const { mentionType } = item;
        if (mentionType === "user") {
          mentionElement = {
            previewText: item.name || item.email,
            userId: item.id,
            type: ELEMENT_MENTION,
            mentionType: item.mentionType,
            value: item.id,
            id: uuid(),
          };
        } else if (mentionType === "group") {
          mentionElement = {
            previewText: item.name,
            groupId: item.id,
            type: ELEMENT_MENTION,
            mentionType: item.mentionType,
            value: item.id,
            id: uuid(),
          };
        } else if (mentionType === "table") {
          mentionElement = {
            previewText: `${item.databaseName ? item.databaseName + "." : ""}${
              item.schemaName
            }.${item.tableName}`,
            type: ELEMENT_MENTION,
            mentionType: item.mentionType,
            table: item.tableName,
            schema: item.schemaName,
            database: item.databaseName,
            value: item.id,
            tableId: item.id,
            id: uuid(),
            connectionId: item.connectionId,
          };
        } else if (mentionType === "dataframe") {
          mentionElement = {
            previewText: item.name,
            type: ELEMENT_MENTION,
            mentionType: item.mentionType,
            value: item.name,
            id: uuid(),
            name: item.name,
            connectionId: item.connectionId,
          };
        } else if (mentionType === "hex") {
          mentionElement = {
            previewText: item.title,
            type: ELEMENT_MENTION,
            mentionType: item.mentionType,
            id: uuid(),
            hexId: item.id,
            value: item.id,
            hexType: item.hexType,
          };
        } else {
          assertNever(mentionType, mentionType);
        }
        return mentionElement;
      },
    },
  });
}

// ----------------------------------------------------------------------------
// View Element
// ----------------------------------------------------------------------------

const MentionElement: PlatePluginComponent = (props) => (
  <MentionElementRenderer {...props} element={props.element} />
);

// ----------------------------------------------------------------------------
// Input Element
// ----------------------------------------------------------------------------

const onSelectMentionItem = getMentionOnSelectItem();

const SchemaLabel = styled.span`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const RightIconContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MentionComboboxItem = React.memo(function MentionComboboxItem({
  editor,
  item,
  onSelectSideEffect,
  search,
}: {
  editor: PlateEditor<Value>;
  item: RichTextMentionData;
  onSelectSideEffect?: (
    editor: PlateEditor<Value>,
    item: RichTextMentionData,
  ) => void;
  search: string;
}) {
  const mentionType = item.mentionType;

  let text: JSX.Element | string = "";
  let icon: JSX.Element;
  let label: JSX.Element | string | null = null;

  if (mentionType === "user") {
    text = item.name || item.email;
    label = item.email;
    icon = (
      <UserAvatar
        imageUrl={item.imageUrl ?? undefined}
        name={item.name ?? undefined}
        size={20}
        tooltip={false}
      />
    );
  } else if (mentionType === "group") {
    text = item.name;
    icon = <GroupAvatar name={item.name} />;
  } else if (mentionType === "table") {
    const pathSegments = `${item.databaseName ? item.databaseName + "." : ""}${
      item.schemaName
    }.${item.tableName}`.split(".");
    text = (
      <>
        {pathSegments.slice(0, -1).map((t: string, i: number) => (
          <SchemaLabel key={i}>{t}/</SchemaLabel>
        ))}
        {last(pathSegments)}
      </>
    );
    label = (
      <RightIconContainer>
        {item.pinned && <PinnedTableIcon />}
        {item.status && (
          <StatusLabel condensed={true} minimal={true} status={item.status} />
        )}
      </RightIconContainer>
    );
    icon = <TableCellIcon />;
  } else if (mentionType === "dataframe") {
    text = item.name;
    icon = <DataframeIcon />;
  } else if (mentionType === "hex") {
    text = item.title;
    icon =
      item.hexType === HexType.PROJECT ? <ProjectIcon /> : <ComponentIconV2 />;
  } else {
    // We could run into this case at runtime if there are other mention plugins
    // because plate is bad and merges all items for all combobox plugins into
    // a single list in its store.
    // This callback should be responsible for handling all mention types defined
    // in this plugin which will be caught at compile time by the above assertNever
    // but should handle items from other mentions gracefully (via noop).
    guardNever(mentionType, mentionType);
    return null;
  }

  return (
    <RichTextComboboxItem
      icon={icon}
      isMonospace={
        item.mentionType === "dataframe" || item.mentionType === "table"
      }
      label={label}
      text={text}
      value={item.id}
      onClick={(evt) => {
        // handle it ourselves on mouse down as we need to stop propagation to handle cases
        // where the mentions are rendered in a popover/tooltip, such as in comments
        evt.stopPropagation();
        // prevent losing focus of editor
        evt.preventDefault();
        onSelectSideEffect?.(editor, item);
        onSelectMentionItem(editor, { text: item.id, ...item }, search);
        // Plate doesn't handle click and edit immediately after a non-text element well, so we
        // insert a space after the mention
        insertNodes(editor, { text: " " });
      }}
    />
  );
});

export const MentionInputElement = forwardRef<
  typeof PlateElement,
  PlateElementProps
>(function MentionInputElement({ className, ...props }, ref) {
  const { children, editor, element } = props;
  const [search, setSearch] = useState("");
  const { onMentionSearch, onSelectSideEffect } = useRichTextMentionContext();
  const [suggestions, setSuggestions] = useState<
    readonly RichTextMentionData[]
  >([]);

  useEffect(() => {
    if (search == null) {
      return;
    }
    let cancelled = false;
    void onMentionSearch(search).then((results) => {
      const isDataSearch = !!results.find(
        (i) => i.mentionType === "dataframe" || i.mentionType === "table",
      );
      if (!cancelled) {
        setSuggestions(
          isDataSearch ? results.slice(0, 20) : results.slice(0, 5),
        );
      }
    });
    return () => {
      cancelled = true;
    };
  }, [onMentionSearch, search]);

  const magicMentionDetails = useHexFlag("magic-mention-details");
  const renderDetails = useCallback(
    (itemValue: string) => {
      const mentionItem = suggestions.find((item) => item.id === itemValue);
      if (mentionItem) {
        if (mentionItem.mentionType === "table") {
          return <TableMentionDetails mention={mentionItem} />;
        } else if (mentionItem.mentionType === "dataframe") {
          return <DataframeMentionDetails mention={mentionItem} />;
        }
      }
      return null;
    },
    [suggestions],
  );

  return (
    <PlateElement
      ref={ref}
      as="span"
      data-slate-value={element.value}
      {...props}
    >
      <RichTextCombobox
        element={element}
        setValue={setSearch}
        trigger="@"
        value={search}
        onRenderDetails={magicMentionDetails ? renderDetails : undefined}
      >
        {suggestions.map((suggestion) => (
          <MentionComboboxItem
            key={suggestion.id}
            editor={editor}
            item={suggestion}
            search={search}
            onSelectSideEffect={onSelectSideEffect}
          />
        ))}
      </RichTextCombobox>
      {children}
    </PlateElement>
  );
});

// ----------------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------------

export function createMentionPluginComponents(): Record<
  string,
  PlatePluginComponent
> {
  return {
    [ELEMENT_MENTION]: MentionElement,
    [ELEMENT_MENTION_INPUT]: MentionInputElement,
  };
}
