import {
  Combobox,
  ComboboxItem,
  type ComboboxItemProps,
  ComboboxPopover,
  ComboboxProvider,
  Portal,
  useComboboxContext,
  useComboboxStore,
} from "@ariakit/react";
import { useHTMLInputCursorState } from "@udecode/plate-combobox";
import {
  Hotkeys,
  type TElement,
  createPointRef,
  findNodePath,
  focusEditor,
  getPointBefore,
  insertText,
  moveSelection,
  removeNodes,
  useComposedRef,
  useEditorRef,
  useElement,
} from "@udecode/plate-common";
import React, {
  type HTMLAttributes,
  type ReactNode,
  type RefObject,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { PointRef } from "slate";
import { useSelected } from "slate-react";
import styled, { css } from "styled-components";

import { Keys } from "../../../util/Keys";

// ----------------------------------------------------------------------------
// Context
// ----------------------------------------------------------------------------

interface ComboboxContextValue {
  inputProps: UseComboboxInputResult["props"];
  inputRef: RefObject<HTMLInputElement>;
  removeInput: UseComboboxInputResult["removeInput"];
  setHasEmpty: (hasEmpty: boolean) => void;
  trigger: string;
}

const ComboboxContext = createContext<ComboboxContextValue>(null as any);

// ----------------------------------------------------------------------------
// Input
// ----------------------------------------------------------------------------

const InputWrapper = styled.span`
  position: relative;
  min-height: 1lh;
`;

const InputPlaceholder = styled.span`
  visibility: hidden;
  overflow: hidden;
  text-wrap: nowrap;
`;

const StyledCombobox = styled(Combobox)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

export const ComboboxInput = forwardRef<
  HTMLInputElement,
  HTMLAttributes<HTMLInputElement>
>(function ComboboxInput({ className, ...props }, propRef) {
  const {
    inputProps,
    inputRef: contextRef,
    trigger,
  } = useContext(ComboboxContext);
  const store = useComboboxContext()!;
  const value = store.useState("value");
  const ref = useComposedRef(propRef, contextRef);

  /**
   * To create an auto-resizing input, we render a visually hidden span
   * containing the input value and position the input element on top of it.
   * This works well for all cases except when input exceeds the width of the
   * container.
   */
  return (
    <>
      {trigger}
      <InputWrapper onKeyDown={stopKeyEventPropagation}>
        <InputPlaceholder>{value || "\u200B"}</InputPlaceholder>
        <StyledCombobox
          ref={ref}
          autoSelect={true}
          className={className}
          value={value}
          {...inputProps}
          {...props}
        />
      </InputWrapper>
    </>
  );
});

// ----------------------------------------------------------------------------
// Popover
// ----------------------------------------------------------------------------

const StyledPopover = styled(ComboboxPopover)`
  background: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};
  z-index: 500;
  min-width: 180px;
  max-width: 700px;
  max-height: 170px;
  padding: 5px;
  margin: 3px 0;
  overflow-y: auto;
`;

const stopKeyEventPropagation = (e: React.KeyboardEvent<HTMLElement>) => {
  e.stopPropagation();
};

const stopMouseEventPropagation = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

export const ComboboxContent: typeof ComboboxPopover = ({
  className,
  ...props
}) => {
  // Portal prevents CSS from leaking into popover
  return (
    <Portal>
      <StyledPopover
        className={className}
        {...props}
        onKeyDown={stopKeyEventPropagation}
        onMouseDown={stopMouseEventPropagation}
      />
    </Portal>
  );
};

// ----------------------------------------------------------------------------
// Empty State
// ----------------------------------------------------------------------------

const EmptyStateLabel = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  position: relative;
  display: flex;
  user-select: none;
  align-items: center;
  min-height: 30px;
  padding: 3px 6px;
`;

export const ComboboxEmptyState = ({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  const { setHasEmpty } = useContext(ComboboxContext);
  const store = useComboboxContext()!;
  const items = store.useState("items");

  useEffect(() => {
    setHasEmpty(true);
    return () => setHasEmpty(false);
  }, [setHasEmpty]);

  if (items.length > 0) return null;

  return <EmptyStateLabel className={className}>{children}</EmptyStateLabel>;
};

// ----------------------------------------------------------------------------
// Combobox
// ----------------------------------------------------------------------------

const DetailContainer = styled.div`
  color: inherit;
  background: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};
  position: fixed;
  top: 3px;
  left: calc(100% + 4px);
`;

export interface RichTextComboboxProps {
  children: ReactNode;
  element: TElement;
  trigger: string;
  setValue?: (value: string) => void;
  value?: string;
  cancelInputOnSpace?: boolean;
  emptyMessage?: string;
  onRenderDetails?: (itemValue: string) => React.ReactElement | null;
}

/**
 * Based on code supplied in "Manual Installation" at https://platejs.org/docs/components/inline-combobox
 * - replace Tailwind styles with styled-components, leveraging our theme
 */
export const RichTextCombobox = ({
  cancelInputOnSpace = false,
  children,
  element,
  emptyMessage = "No results found",
  onRenderDetails,
  setValue: setValueProp,
  trigger,
  value: valueProp,
}: RichTextComboboxProps) => {
  const editor = useEditorRef();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const cursorState = useHTMLInputCursorState(inputRef);

  const [valueState, setValueState] = useState("");
  const hasValueProp = valueProp !== undefined;
  const value = hasValueProp ? valueProp : valueState;

  const setValue = useCallback(
    (newValue: string) => {
      setValueProp?.(newValue);

      if (!hasValueProp) {
        setValueState(newValue);
      }
    },
    [setValueProp, hasValueProp],
  );

  /**
   * Track the point just before the input element so we know where to
   * insertText if the combobox closes due to a selection change.
   */
  const [insertPoint, setInsertPoint] = useState<PointRef | null>(null);

  useEffect(() => {
    const path = findNodePath(editor, element);

    if (!path) return;

    const point = getPointBefore(editor, path);

    if (!point) return;

    const pointRef = createPointRef(editor, point);
    setInsertPoint(pointRef);

    return () => {
      pointRef.unref();
    };
  }, [editor, element]);

  const { props: inputProps, removeInput } = useComboboxInput({
    cancelInputOnBlur: false,
    cancelInputOnSpace,
    cursorState,
    onCancelInput: (cause) => {
      if (cause !== "backspace") {
        insertText(editor, trigger + value, {
          at: insertPoint?.current ?? undefined,
        });
      }
      if (cause === "arrowLeft" || cause === "arrowRight") {
        moveSelection(editor, {
          distance: 1,
          reverse: cause === "arrowLeft",
        });
      }
    },
    ref: inputRef,
  });

  const [hasEmpty, setHasEmpty] = useState(false);

  const contextValue: ComboboxContextValue = useMemo(
    () => ({
      inputProps,
      inputRef,
      removeInput,
      setHasEmpty,
      trigger,
    }),
    [trigger, inputRef, inputProps, removeInput, setHasEmpty],
  );

  const store = useComboboxStore({
    // open: ,
    setValue: (newValue) => setValue(newValue),
  });

  const items = store.useState("items");

  const [activeValue, setActiveValue] = useState<string>();
  const activeId = store.useState("activeId");

  useEffect(() => {
    if (activeId) setActiveValue(store.item(activeId)?.value);
  }, [activeId, store]);

  /**
   * If there is no active ID and the list of items changes, select the first
   * item.
   */
  useEffect(() => {
    if (!store.getState().activeId) {
      store.setActiveId(store.first());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, store]);

  return (
    <span contentEditable={false}>
      <ComboboxProvider open={items.length > 0 || hasEmpty} store={store}>
        <ComboboxContext.Provider value={contextValue}>
          <ComboboxInput />
          <ComboboxContent>
            <ComboboxEmptyState>{emptyMessage}</ComboboxEmptyState>
            {children}
            {activeValue && onRenderDetails && (
              <DetailContainer onClick={() => removeInput(true)}>
                {onRenderDetails(activeValue)}
              </DetailContainer>
            )}
          </ComboboxContent>
        </ComboboxContext.Provider>
      </ComboboxProvider>
    </span>
  );
};

// ----------------------------------------------------------------------------
// Item
// ----------------------------------------------------------------------------

const StyledComboboxItem = styled(ComboboxItem)<{ $mono?: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius};
  position: relative;
  display: flex;
  user-select: none;
  align-items: center;
  min-height: 30px;
  padding: 3px 6px;
  cursor: pointer;
  gap: 6px;
  ${({ $mono, theme }) =>
    $mono &&
    css`
      font-family: ${theme.fontFamily.MONO};
    `}
  transition:
    background-color ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing},
    color ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing};
  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }
  &:active {
    background-color: ${({ theme }) => theme.hoverColor};
  }
  &[data-active-item="true"] {
    color: ${({ theme }) => theme.menuItem.activeText};
    background-color: ${({ theme }) => theme.menuItem.activeBackground};
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

const TextContainer = styled.span`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 6px;
`;

const LabelContainer = styled.span`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

export type RichTextComboboxItemProps = {
  text: JSX.Element | string;
  icon?: JSX.Element | string;
  label?: ReactNode;
  isMonospace?: boolean;
} & ComboboxItemProps &
  Required<Pick<ComboboxItemProps, "value">>;

export const RichTextComboboxItem = ({
  className,
  icon,
  isMonospace,
  label,
  onClick,
  text,
  ...props
}: RichTextComboboxItemProps) => {
  const { removeInput } = useContext(ComboboxContext);

  return (
    <StyledComboboxItem
      $mono={isMonospace}
      className={className}
      onClick={(event) => {
        removeInput(true);
        onClick?.(event);
      }}
      {...props}
      focusOnHover={true}
    >
      {icon}
      <TextContainer>
        <span>{text}</span>
        {label && <LabelContainer>{label}</LabelContainer>}
      </TextContainer>
    </StyledComboboxItem>
  );
};

// ----------------------------------------------------------------------------
// input state
// derived from: https://github.com/udecode/plate/blob/main/packages/combobox/src/hooks/useComboboxInput.ts
// ----------------------------------------------------------------------------

type ComboboxInputCursorState = {
  atEnd: boolean;
  atStart: boolean;
};

type CancelComboboxInputCause =
  | "arrowLeft"
  | "arrowRight"
  | "backspace"
  | "blur"
  | "deselect"
  | "escape"
  | "manual"
  | "space";

interface UseComboboxInputOptions {
  ref: RefObject<HTMLElement>;
  autoFocus?: boolean;
  cancelInputOnArrowLeftRight?: boolean;
  cancelInputOnBackspace?: boolean;
  cancelInputOnBlur?: boolean;
  cancelInputOnDeselect?: boolean;
  cancelInputOnEscape?: boolean;
  cancelInputOnSpace?: boolean;
  cursorState?: ComboboxInputCursorState;
  forwardUndoRedoToEditor?: boolean;
  onCancelInput?: (cause: CancelComboboxInputCause) => void;
}

interface UseComboboxInputResult {
  cancelInput: (
    cause?: CancelComboboxInputCause,
    focusEditor?: boolean,
  ) => void;
  props: Required<Pick<HTMLAttributes<HTMLElement>, "onBlur" | "onKeyDown">>;
  removeInput: (focusEditor?: boolean) => void;
}

const useComboboxInput = ({
  autoFocus = true,
  cancelInputOnArrowLeftRight = true,
  cancelInputOnBackspace = true,
  cancelInputOnBlur = true,
  cancelInputOnDeselect = true,
  cancelInputOnEscape = true,
  cancelInputOnSpace = false,
  cursorState,
  forwardUndoRedoToEditor = true,
  onCancelInput,
  ref,
}: UseComboboxInputOptions): UseComboboxInputResult => {
  const editor = useEditorRef();
  const element = useElement();
  const selected = useSelected();

  const cursorAtStart = cursorState?.atStart ?? false;
  const cursorAtEnd = cursorState?.atEnd ?? false;

  const removeInput = useCallback(
    (shouldFocusEditor = false) => {
      const path = findNodePath(editor, element);

      if (!path) return;

      removeNodes(editor, { at: path });

      if (shouldFocusEditor) {
        focusEditor(editor);
      }
    },
    [editor, element],
  );

  const cancelInput = useCallback(
    (cause: CancelComboboxInputCause = "manual", shouldFocusEditor = false) => {
      removeInput(shouldFocusEditor);
      onCancelInput?.(cause);
    },
    [onCancelInput, removeInput],
  );

  /**
   * Using autoFocus on the input element causes an error: Cannot resolve a
   * Slate node from DOM node: [object HTMLSpanElement]
   */
  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus, ref]);

  /**
   * Storing the previous selection lets us determine whether the input has been
   * actively deselected. When undoing or redoing causes a combobox input to be
   * inserted, selected can be temporarily false. Removing the input at this
   * point is incorrect and crashes the editor.
   */
  const previousSelected = useRef(selected);

  useEffect(() => {
    if (previousSelected.current && !selected && cancelInputOnDeselect) {
      cancelInput("deselect");
    }

    previousSelected.current = selected;
  }, [selected, cancelInputOnDeselect, cancelInput]);

  return {
    cancelInput,
    props: {
      onBlur: () => {
        if (cancelInputOnBlur) {
          cancelInput("blur");
        }
      },
      onKeyDown: (event) => {
        if (cancelInputOnEscape && event.key === Keys.ESCAPE) {
          cancelInput("escape", true);
        }
        if (
          cancelInputOnBackspace &&
          cursorAtStart &&
          event.key === Keys.BACKSPACE
        ) {
          cancelInput("backspace", true);
        }
        if (
          cancelInputOnArrowLeftRight &&
          cursorAtStart &&
          event.key === Keys.ARROW_LEFT
        ) {
          cancelInput("arrowLeft", true);
        }
        if (
          cancelInputOnArrowLeftRight &&
          cursorAtEnd &&
          event.key === Keys.ARROW_RIGHT
        ) {
          cancelInput("arrowRight", true);
        }
        if (cancelInputOnSpace && event.key === Keys.SPACE) {
          cancelInput("space", true);
        }

        const isUndo = Hotkeys.isUndo(event) && editor.history.undos.length > 0;
        const isRedo = Hotkeys.isRedo(event) && editor.history.redos.length > 0;

        if (forwardUndoRedoToEditor && (isUndo || isRedo)) {
          event.preventDefault();
          editor[isUndo ? "undo" : "redo"]();
          focusEditor(editor);
        }
      },
    },
    removeInput,
  };
};
