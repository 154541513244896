import {
  TriggerComboboxPlugin,
  withTriggerCombobox,
} from "@udecode/plate-combobox";
import {
  EElementOrText,
  type PlateEditor,
  PlateElement,
  PlateElementProps,
  PlatePluginComponent,
  type Value,
  createPluginFactory,
  getPluginOptions,
  insertNodes,
} from "@udecode/plate-common";
import { filter } from "fuzzaldrin-plus";
import React, { forwardRef, useEffect, useState } from "react";

import { EmojiItem, getEmojis } from "../../common/emoji/getEmojis.js";

import { RichTextCombobox, RichTextComboboxItem } from "./RichTextCombobox";

// ----------------------------------------------------------------------------
// Plugin
// ----------------------------------------------------------------------------

export const KEY_EMOJI = "emoji";

export const ELEMENT_EMOJI_INPUT = "emoji_input";

export interface EmojiPlugin<TEmoji extends EmojiItem = EmojiItem>
  extends TriggerComboboxPlugin {
  createEmojiNode?: (emoji: TEmoji) => EElementOrText<any>;
}

export const createEmojiPlugin = createPluginFactory<EmojiPlugin>({
  key: KEY_EMOJI,
  options: {
    createComboboxInput: () => ({
      children: [{ text: "" }],
      type: ELEMENT_EMOJI_INPUT,
    }),
    createEmojiNode: (item: EmojiItem) => ({ text: item.emoji }),
    trigger: ":",
    triggerPreviousCharPattern: /^\s?$/,
  },
  plugins: [
    {
      isElement: true,
      isInline: true,
      isVoid: true,
      key: ELEMENT_EMOJI_INPUT,
    },
  ],
  withOverrides: withTriggerCombobox,
});

export const insertEmoji = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>,
  TEmoji extends EmojiItem = EmojiItem,
>(
  editor: E,
  emoji: TEmoji,
) => {
  const { createEmojiNode } = getPluginOptions<EmojiPlugin, V, E>(
    editor,
    KEY_EMOJI,
  );

  const emojiNode = createEmojiNode!(emoji);
  insertNodes(editor, emojiNode as EElementOrText<V>);
};

// ----------------------------------------------------------------------------
// Input Element
// ----------------------------------------------------------------------------

const MAX_SUGGESTIONS = 50;

export const EmojiInputElement = forwardRef<
  typeof PlateElement,
  PlateElementProps
>(function EmojiInputElement({ className, ...props }, ref) {
  const { children, editor, element } = props;
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<EmojiItem[]>([]);

  useEffect(() => {
    let isMounted = true;
    const updateItems = async () => {
      let emojis = await getEmojis();

      if (search != null && search.trim().length > 0) {
        emojis = filter(emojis, search, { key: "name" });
      }

      if (isMounted) {
        setItems(emojis.slice(0, MAX_SUGGESTIONS));
      }
    };
    updateItems().catch(console.error);
    return () => {
      isMounted = false;
    };
  }, [search]);

  return (
    <PlateElement
      ref={ref}
      as="span"
      data-slate-value={element.value}
      {...props}
    >
      <RichTextCombobox
        cancelInputOnSpace={true}
        element={element}
        emptyMessage="No matching emoji found"
        setValue={setSearch}
        trigger=":"
        value={search}
      >
        {items.map((item) => (
          <RichTextComboboxItem
            key={item.name}
            icon={item.emoji}
            text={item.name}
            value={item.name}
            onClick={() => insertEmoji(editor, item)}
          />
        ))}
      </RichTextCombobox>
      {children}
    </PlateElement>
  );
});

// ----------------------------------------------------------------------------
// Components
// ----------------------------------------------------------------------------

export function createEmojiPluginComponents(): Record<
  string,
  PlatePluginComponent
> {
  return {
    [ELEMENT_EMOJI_INPUT]: EmojiInputElement,
  };
}
